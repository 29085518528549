<template>
  <v-combobox
    placeholder="What's the ingredient?"
    chips small-chips
    label="Ingredient"
    :items="storeInventory"
    item-text="name"
    return-object
    v-model="ingredientToAdd.type"
    :search-input.sync="ingredientSearch"
    hint="Press enter/return to pick a value not shown."
    @change="ingredientChanged"
    :filter="filter"
  >
    <!-- The change above is called when component is first loaded  -->
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading">Hit <kbd>Enter</kbd>, <kbd>⏎</kbd>, or <kbd>return</kbd> to add <v-chip color="success" small>{{ingredientSearch}}</v-chip></span>
      </v-list-item>
    </template>

    <!-- Custom Ingredient Type Selector -->
    <template v-slot:append-outer>
      <v-menu rounded="b-xl" offset-y v-model="newIngredType">
        <template v-slot:activator="{ attrs, on }">
          <v-btn color="info  " small text v-bind="attrs" v-on="on">{{displayIngredientType}}
            <v-icon>{{newIngredType ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="ingredientToAddType" @change="updateIngredientType" mandatory>
            <v-list-item v-for="ingred in ingredientTypes" :key="ingred.value" :value="ingred.value">
              <v-list-item-title v-text="ingred.name"></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:selection="data">
      <v-chip :color="getIngredientColor(data.item.type)">
        <v-avatar color="grey lighten-4" left>
          <span>{{data.item.icon || '🧊'}}</span>
        </v-avatar>
        {{typeof data.item == 'string' ? data.item : data.item.name}}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else-if="data.item.ingredientType == 'inventory'">
        <v-list-item-avatar>
          <v-avatar color="grey lighten-4">
            <span class="headline">{{getEmoji(data.item.type)}}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="data.item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="data.item.subtitle"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-else>
        <!-- If it's a generic item, and note an inventory item  -->
        <v-list-item-avatar>
          <v-avatar color="grey lighten-4">
            <span class="headline">{{data.item.icon != null ? data.item.icon : '🧊'}}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-combobox>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'IngredientPicker',
    props: {
      ingredient: {
        type: Object,
        default: function(){
          return {
            customMeasurement: '',
            measurement: 'oz',
            qty: '',
            type: 'other'
          }
        }
      }
    },
    data: () => ({
      ingredientSearch: '',
      ingredientToAddType: 'other',

      ingredientToAdd: {},

      newIngredType: false,

      ingredientTypes: [
        { name: 'Whiskey 🥃', value: 'whiskey'},
        { name: 'Brandy 🍷',  value: 'brandy' },
        { name: 'Vodka 🥔',   value: 'vodka'  },
        { name: 'Rum 🍹',     value: 'rum'    },
        { name: 'Gin 🍸',     value: 'gin'    },
        { name: 'Tequila 🌵', value: 'tequila'},
        { name: 'Liqueur 🥂', value: 'liqueur'},
        { name: 'Other 🧊',   value: 'other'  },
      ],
    }),
    mounted(){
      // If something is passed into the component, setup all the internals
      this.ingredientToAdd = Object.keys(this.ingredient).length === 0
      console.log(this.ingredientToAdd)

      var passedInIngredientType = this.ingredientTypes.some( item => this.ingredientToAdd.type && item.value == this.ingredientToAdd.type.type)
                                                                                      ? this.ingredientTypes.find(item => item.value == this.ingredientToAdd.type.type.toLowerCase())
                                                                                      : {name: 'Other 🧊', value: 'other'};
      console.log(passedInIngredientType)

      this.setNewIngredientType(passedInIngredientType.value)
    },
    computed: {
      ...mapGetters({
        storeInventory: 'getPossibleIngredients',
      }),
      displayIngredientType(){
        var foundItem = this.ingredientTypes.find(item => item.value == this.ingredientToAddType)
        // console.log(foundItem.name)
        return foundItem.name
      }
    },

    methods: {
      filter(item, queryText, itemText){
        var foundAnItem = Object.values(item).some( val => {
          // console.log(val)
          if(typeof val === "number" || typeof val == "boolean"){
            return false;
          }

          if(val.toLowerCase().indexOf(queryText.toLowerCase()) >= 0){
            return true;
          } else {
            return false;
          }
        });

        // console.log(foundAnItem)
        if(!foundAnItem){
          return false;
        }

        //If it's a header or divider, let it in
        if(Object.values(item).length == 1){
          return true;
        }

        return Object.values(item).some( val => {
          // console.log(val)
          if(typeof val === "number" || typeof val == "boolean"){
            return false;
          }

          if(val.toLowerCase().indexOf(queryText.toLowerCase()) >= 0){
            return true;
          } else {
            return false;
          }
        })
      },
      getEmoji(itemType){
        var searchTerm = ''

        if(itemType == null){
          return;
        } else {
          searchTerm = itemType
        }

        if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)/i.test(searchTerm)){
          return '🥃';
        }

        // Brandy 🍷
        else if(/br\w*nd\w*y/i.test(searchTerm)){
          return '🍷';
        }

        // Vodka 🥔
        else if(/(vodka)/i.test(searchTerm)){
          return '🥔'
        }

        // Rum 🍹
        else if(/\b(rh*ums?)\b/i.test(searchTerm)){
          return '🍹';
        }

        // Gin 🍸
        else if(/\b(gin)\b/i.test(searchTerm)){
          return '🍸'
        }

        // Tequila 🌵
        else if(/\b(te(c|q)\w*la)|sotol|rep(o|a)sado\b/i.test(searchTerm)){
          return '🌵'
        }

        // Liqueur 🥂'
        else if(/\b(liq|lic)\w*r\b/i.test(searchTerm)){
          return '🥂'
        }

        else {
          return '🧊'
        }
      },
      ingredientChanged(value){
        console.log(value);
        var searchTerm = ''

        if(value == null){
          return;
        }

        if(typeof value === 'object'){
          searchTerm = `${value.name} ${value.type}`
        } else {
          searchTerm = value
        }

        if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)\b/i.test(searchTerm)){
          this.setNewIngredientType('whiskey');
          // this.setNewIngredientType('Whiskey 🥃')
        }

        // Brandy 🍷
        else if(/br\w*nd\w*y\b/i.test(searchTerm)){
          this.setNewIngredientType('brandy');
          // this.setNewIngredientType('Brandy 🍷')
        }

        // Vodka 🥔
        else if(/(vodka)\b/i.test(searchTerm)){
          this.setNewIngredientType('vodka');
          // this.setNewIngredientType('Vodka 🥔')
        }

        // Rum 🍹
        else if(/\b(rh*ums?)\b/i.test(searchTerm)){
          this.setNewIngredientType('rum');
          // this.setNewIngredientType('Rum 🍹')
        }

        // Gin 🍸
        else if(/\b(gin)\b/i.test(searchTerm)){
          this.setNewIngredientType('gin');
          // this.setNewIngredientType('Gin 🍸')
        }

        // Tequila 🌵
        else if(/\b(te(c|q)\w*la)|sotol|rep(o|a)sado\b/i.test(searchTerm)){
          this.setNewIngredientType('tequila');
          // this.setNewIngredientType('Tequila 🌵')
        }

        // Liqueur 🥂'
        else if(/\b(liq|lic)\w*r\b/i.test(searchTerm)){
          this.setNewIngredientType('liqueur');
          // this.setNewIngredientType('Liqueur 🥂');
        }

        else {
          this.setNewIngredientType('other');
          // this.setNewIngredientType('Other 🧊');
        }

        this.$emit('update:ingredient', this.ingredientToAdd)

        // console.log(value);
      },
      setNewIngredientType(ingredient){
        var updatedIngredType = this.ingredientToAdd.type ? this.ingredientToAdd.type : ingredient;
        console.log(updatedIngredType)

        updatedIngredType.type = ingredient;
        console.log(updatedIngredType)

        this.ingredientToAdd.type = updatedIngredType;
        this.ingredientToAddType = ingredient;
      },
      updateIngredientType(value){
        // When the ingredient type is manually changed, this function needs to be trigerred in order for the ingredient type to be updated
        console.log(value)
        this.setNewIngredientType(value)
      },
      getIngredientColor(ingredientType = ''){
        let type = ingredientType.type != undefined ? ingredientType.type.type.toLowerCase() : '';

        switch(type){
          case 'whiskey':
            return 'yellow darken-3';
            break;
          case 'whisky':
            return 'yellow darken-3';
            break;
          case 'brandy':
            return 'red';
            break;
          case 'vodka':
            return 'brown';
            break;
          case 'rum':
            return 'deep-orange';
            break;
          case 'gin':
            return 'green';
            break;
          case 'tequila':
            return 'light-green';
            break;
          case 'liqueur':
            return 'teal';
            break;
          default:
            return 'blue-grey';
        }
      }
    }
  }
</script>