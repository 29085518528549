import { App } from '../firebase/app';
require('firebase/functions');

// Helper Methods
class Recipe {
  glass;
  sourceId;
  ingredients = [];
  name;
  ownerId;
  steps = [];
  tags = [];
  thumbnail;
  source;

  constructor(drinkJSON){
    // Get the easy things
    this.glass      = drinkJSON.strGlass;
    this.sourceId   = drinkJSON.id;
    this.name       = drinkJSON.strDrink;
    this.tags       = drinkJSON.strTags == null ? null : drinkJSON.strTags.split(',');
    this.thumbnail  = drinkJSON.strDrinkThumb;
    this.source     = {name: 'CocktailDB', link: 'https://www.thecocktaildb.com/'}

    // Parse ingredients
    for(var i = 0; i < 15; i++){
      if(drinkJSON[`strIngredient${i+1}`] == null){
        // If there's nothing to add, break
        break;
      }
      
      if(drinkJSON[`strMeasure${i+1}`] == null){
        // Nothing to add, so break
        break;
      }
      let ingredientMeasurement = drinkJSON[`strMeasure${i+1}`].split(" ");
      const [ingQty = 1, ingMsrmnt = ""] = ingredientMeasurement
      
      this.ingredients.push({
        measurement: ingMsrmnt,
        qty: ingQty,
        type: {
          type: 'generic', 
          name: drinkJSON[`strIngredient${i+1}`], 
          ingredientType: 'generic',
        }
      }) 

    }

    // Parse instructions
    let tempInstructions = drinkJSON['strInstructions'].replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|")
    tempInstructions.forEach(item => this.steps.push(item))
    
  }
}


// Cloud Firestore Functions
var cloudFunctions = App.functions();

// If testing, do the following 
if(location.hostname === 'localhost'){
  cloudFunctions.useFunctionsEmulator('http://localhost:5001')
}

/**
 * Popular Drinks
 */
var getPopularDrinksFunction = cloudFunctions.httpsCallable('getPopularRecipes')

/**
 * 
 * @param {function()} cb returns a function that has an array of popular drinks
 */
function getPopularDrinks(cb){  
  var popularDrinks = window.localStorage.getItem('popularDrinks');
  
  // If set 
  if(popularDrinks !== null){
    var parsedDrinks = JSON.parse(popularDrinks)
    var curDate = new Date()
    var ttl = new Date(parsedDrinks.ttl)
    
    if(curDate.getTime() < ttl.getTime()){
      cb(parsedDrinks.data)
    } else {
      getPopularDrinksFunction()
        .then( (result) => {
          const { drinks } = result.data
          var response = []; 
          
          drinks.forEach( (drink) => {
            let recipe = new Recipe(drink);
            response.push(recipe);
          })
          
          var currentDate = new Date()
          currentDate.setDate(currentDate.getDate() + 2);
          
          var stringifiedTtl = currentDate.toJSON()
          
          window.localStorage.setItem('popularDrinks', JSON.stringify(
            {
              data: response, 
              ttl: stringifiedTtl
            }
          ));
          
          cb(response);
          
        })
        .catch( err => {
          console.log(err);
        }) 
    }
    
  } else {
    getPopularDrinksFunction()
      .then( (result) => {
        const { drinks } = result.data
        var response = []; 
        
        drinks.forEach( (drink) => {
          let recipe = new Recipe(drink);
          response.push(recipe);
        })
        
        var currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + 2);
        
        var stringifiedTtl = currentDate.toJSON()
        
        window.localStorage.setItem('popularDrinks', JSON.stringify(
          {
            data: response, 
            ttl: stringifiedTtl
          }
        ));
        
        cb(response);
        
      })
      .catch( err => {
        console.log(err);
      }) 
  }
    
}

/**
 * Get Drinks by Ingredients
 */
var getDrinksByIngredientsFunction = cloudFunctions.httpsCallable('getDrinksByIngredients')

function getDrinksByIngredients(ingredientsList, cb){

  getDrinksByIngredientsFunction({ingredients: ingredientsList})
    .then( (result) => {

      console.log(result);

      const { drinks } = result.data;

      if(!Array.isArray(drinks)){
        cb([]);
        return;
      }

      drinks.forEach( (drink) => {
        let recipe = new Recipe(drink);
        response.push(recipe);
      })

      cb(response)
    })
    .catch( err => {
      console.log(err)
      cb(err)
    })

}



/**
 * 
 * @param {String} drinkSearchName search string to find a cocktail
 * @param {function()} cb returns a function with the value of the drinks found, if they've been found
 * 
 */

function searchForDrink(drinkSearchName, cb){
  let searchString = encodeURIComponent(drinkSearchName);
  var response = [];
  
  fetch(`https://www.thecocktaildb.com/api/json/v2/1/search.php?s=${searchString}`)
    .then(res => res.json())
    .then(res => {
      // console.log(res)
      const { drinks } = res
      console.log(drinks)

      if(drinks == null){
        cb(response)
        return;
      }
      
      drinks.forEach( (drink) => {
        let recipe = new Recipe(drink);
        response.push(recipe);
      })
      
      cb(response)
      // fragment from AddRecipe
      // this.drinkEntries = drinks
    })
    .catch( err => {
      console.log(err);
      cb(false);
    })
  ;
}

export default {
  getPopularDrinks, searchForDrink, getDrinksByIngredients
}