import {App} from './app'
import 'firebase/firestore'

var DB = App.firestore();
if(location.hostname === 'localhost') {
  DB.settings({
    host: "localhost:8080",
    ssl: false
  })
}

export { DB };