<template>
	<v-combobox
	  :readonly="readonly"
	  label="Subvariant"
	  hint="What subvariant is this? Irish Single Malt Island Whisky?" persistent-hint
	  :items="subvariants"
	  @change="changed"
	  :value="subvariant"
	></v-combobox>
</template>
<script>
	export default {
		name: 'SubvariantPicker', 
		data: () => ({
		}), 
		props: {
			variant: {
				type: String, 
				required: true
			},
			subvariant: String, 
			readonly: {
				type: Boolean, 
				default: function() {
					return false;
				}
			}
		},
		methods: {
			changed(value){
				this.$emit('update:subvariant', value)
			}
		}, 
		computed: {
			subvariants(){
				switch(this.variant) {
				  case 'Single Malt':
					return ["Island","Lowloand","Cambeltown","Highland","Islay","Speyside" ];
					break;
				  case 'Bourbon': 
					return ["Kentucky","Tennessee Whiskey"];
					break;
				  default:
					return [];
					break;
				}
			  }
		}
	}
</script>