import Vue from 'vue'
import IngredientTypePicker from '@/components/IngredientTypePicker'
import ingredientPicker from '@/components/IngredientPicker'
import IngredientSubtypePicker from '@/components/IngredientSubtypePicker'
import IngredientVariantPicker from '@/components/IngredientVariantPicker'
import IngredientSubvariantPicker from '@/components/IngredientSubvariantPicker'
import QtyMeasurementPicker from '@/components/QtyMeasurementPicker'

Vue.component('ingredient-type-picker', IngredientTypePicker)
Vue.component('ingredient-subtype-picker', IngredientSubtypePicker)
Vue.component('ingredient-variant-picker', IngredientVariantPicker)
Vue.component('ingredient-subvariant-picker', IngredientSubvariantPicker)
Vue.component('qty-measurement-picker', QtyMeasurementPicker)
Vue.component('ingredient-picker', ingredientPicker)