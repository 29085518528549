import Vue from 'vue'
import Vuex from 'vuex'
import { DB } from '../firebase/db.js'
import cocktaildb from '../api/cocktaildb'
import { vuexfireMutations, firestoreAction } from 'vuexfire'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    inventory: [], 
    recipes: [],
    collections: [],
    popularRecipes: [],
    inventoryTypes: {
      whisky: {
        name: 'Whiskey',
        primaryColor: 'yellow darken-3', 
        secondaryColor: 'yellow darken-4', 
        emoji: '🥃'
      },
      brandy: {
        name: 'Brandy',
        primaryColor: 'red', 
        secondaryColor: 'red darken-2', 
        emoji: '🍷'
      }, 
      vodka: {
        name: 'Vodka',
        primaryColor: 'brown', 
        secondaryColor: 'brown darken-2', 
        emoji: '🥔'
      }, 
      rum: {
        name: 'Rum',
        primaryColor: 'deep-orange', 
        secondaryColor: 'deep-orange darken-2', 
        emoji: '🍹'
      }, 
      gin: {
        name: 'Gin',
        primaryColor: 'green', 
        secondaryColor: 'green darken-2', 
        emoji: '🍸'
      }, 
      tequila: {
        name: 'Tequila',
        primaryColor: 'light-green', 
        secondaryColor: 'light-green darken-2', 
        emoji: '🌵'
      }, 
      liqueur: {
        name: 'Liqueur',
        primaryColor: 'teal', 
        secondaryColor: 'teal darken-2', 
        emoji: '🥂'
      }
    },
  },
  mutations: {
    ...vuexfireMutations,
    // addInventoryItem(state, item){
    //   let newItem = {id: uuidv5('https://drincolo.com/', uuidv5.URL), ...item};
    //   state.inventory.push(newItem);
    // }, 
    LOGOUT(state){
      state.user.loggedIn = false;
      state.user.data = null;
      state.inventory = [];
      state.recipes = [];
    },
    SET_LOGGED_IN(state, value){
      state.user.loggedIn = value;
    }, 
    SET_USER(state, data) { 
      state.user.data = data
    }, 
    SET_POPULAR_DRINKS(state, data){
      state.popularRecipes = data;
    },
  },
  getters: {
    /**
     * Returns an array containing a list of possible ingredients to add to a recipe
     * @returns {Array} An array that contains inventory, recipe, and generic types 
     */
    getPossibleIngredients: (state) => {
      var returnValue = [];

      if(state.inventory.length >= 1){
        returnValue.push({ header: 'Inventory' });
      
        state.inventory.forEach( item => {
          var newObject = {
            name: `${item.maker}`, 
            subtitle: `${item.type}${item.subtype ? ` - ${item.subtype}` : ''}`,
            ingredientType: 'inventory', 
            icon: '👝',
            ...item
          };
          
          returnValue.push(newObject)
        })
      }
    
      // var returnValue = state.inventory.map( item => {
      //   /**
      //     {
      //      (Classification: type => subtype => variant => subvariant)
      //       👝brand:          (Optional)
      //       👝distiller:      (Optional) 
      //       👝maker:          Name of Ingredient 
      //       👝measurement:    mL, oz, or userdefined 
      //       name:             👝/📖/🛒: Name
      //       👝ownerId:        ownerId
      //       👝qty:            remaining qty left of ingredient
      //       👝subtype:        (Optional) classification under type
      //       👝subvariant:    (Optional) classification under variant
      //       type:            tequila, liqueur, gin, rum, vodka, brandy, whiskey, other
      //       👝variant:       Classification under subtype
      //       ingredientType: inventory
      //     }
      //    */
      //   var returnObject = {
      //     name: `${item.maker}`, 
      //     ingredientType: 'inventory', 
      //     icon: '👝',
      //     ...item
      //   }
      //   return returnObject;
      // }); 
      
      /**
      {
        name:             👝/📖/🛒: Name
        ingredientType:   recipe
        type:             other
      }
      */
      /*
        Recipes
      */

      if(state.recipes.length >= 1){
        if(returnValue.length > 0){
          returnValue.push({ divider: true });
        }

        returnValue.push({ header: 'Recipes' });
        state.recipes.forEach( recipe => {
          returnValue.push({
            name: `${recipe.name}`,
            ingredientType: 'recipe',
            icon: '📖',
            type: 'other', 
          })
        })
      }
      
      /*
        Generics
      */
      if(returnValue.length > 0){
        returnValue.push({ divider: true });
      }
      returnValue.push({ header: 'Generics' });

      // Need to iterate over keys
      for(const [key, value] of Object.entries(state.inventoryTypes)){
        returnValue.push({
          name: value.name, 
          ingredientType: 'generic',
          icon: value.emoji,
          type: value.name,
        })
      }
      
      // state.inventoryTypes.forEach( item => {
      //   returnValue.push({
      //     name: item.name, 
      //     ingredientType: 'generic',
      //     icon: type.icon,
      //     type: type.name.charAt(0).toUpperCase()+type.name.slice(1),
      //   })
      // })
      
      return returnValue;
    },
    inventoryByTypes: (state) => {
      var types = {
        whisky: {
          name: 'Whiskey',
          count: 0,
          primaryColor: 'yellow darken-3', 
          secondaryColor: 'yellow darken-4', 
          emoji: '🥃'
        },
        brandy: {
          name: 'Brandy',
          count: 0,
          primaryColor: 'red', 
          secondaryColor: 'red darken-2', 
          emoji: '🍷'
        }, 
        vodka: {
          name: 'Vodka',
          count: 0,
          primaryColor: 'brown', 
          secondaryColor: 'brown darken-2', 
          emoji: '🥔'
        }, 
        rum: {
          name: 'Rum',
          count: 0,
          primaryColor: 'deep-orange', 
          secondaryColor: 'deep-orange darken-2', 
          emoji: '🍹'
        }, 
        gin: {
          name: 'Gin',
          count: 0,
          primaryColor: 'green', 
          secondaryColor: 'green darken-2', 
          emoji: '🍸'
        }, 
        tequila: {
          name: 'Tequila',
          count: 0,
          primaryColor: 'light-green', 
          secondaryColor: 'light-green darken-2', 
          emoji: '🌵'
        }, 
        liqueur: {
          name: 'Liqueur',
          count: 0,
          primaryColor: 'teal', 
          secondaryColor: 'teal darken-2', 
          emoji: '🥂'
        },
        other: {
          name: 'Other', 
          count: 0, 
          primaryColor: 'blue-grey', 
          secondaryColor: 'blue-grey darken-2', 
          emoji: '🧊'
        }
      };
      
      state.inventory.forEach( (item) => {
        var type = item.type.toLowerCase();
        if(Object.keys(types).includes(type)){
          if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)\b/i.test(type)){
            types['whisky'].count = parseInt(types['whisky'].count) + 1;
          } else {
            types[type].count = parseInt(types[type].count) + 1;
          }
        } else {
          types['other'].count = parseInt(types['other'].count) + 1;
        }
      });
      
      return types; 
    },
    recipeTypesCount: (state) => {
      var types = {
        whisky: {
          name: 'Whisky',
          count: 0,
          primaryColor: 'yellow darken-3', 
          secondaryColor: 'yellow darken-4', 
          emoji: '🥃'
        },
        brandy: {
          name: 'Brandy',
          count: 0,
          primaryColor: 'red', 
          secondaryColor: 'red darken-2', 
          emoji: '🍷'
        }, 
        vodka: {
          name: 'Vodka',
          count: 0,
          primaryColor: 'brown', 
          secondaryColor: 'brown darken-2', 
          emoji: '🥔'
        }, 
        rum: {
          name: 'Rum',
          count: 0,
          primaryColor: 'deep-orange', 
          secondaryColor: 'deep-orange darken-2', 
          emoji: '🍹'
        }, 
        gin: {
          name: 'Gin',
          count: 0,
          primaryColor: 'green', 
          secondaryColor: 'green darken-2', 
          emoji: '🍸'
        }, 
        tequila: {
          name: 'Tequila',
          count: 0,
          primaryColor: 'light-green', 
          secondaryColor: 'light-green darken-2', 
          emoji: '🌵'
        }, 
        liqueur: {
          name: 'Liqueur',
          count: 0,
          primaryColor: 'teal', 
          secondaryColor: 'teal darken-2', 
          emoji: '🥂'
        },
      };
      
      var ezTypes = ['whiskey', 'brandy', 'vodka', 'rum', 'gin', 'tequila', 'liqueur'];
      
      state.recipes.forEach( (recipe) => {
        
        recipe.ingredients.forEach( (ingredient) => {
          
          var ingredientType = ''

          if(typeof ingredient.type == 'object'){
            ingredientType = ingredient.type.type;
          } else {
            // One time added ingredient type 
            ingredientType = ingredient.type;
          }
          
          if(ingredientType.indexOf(' ') >= 0){
            ingredientType.split(' ').forEach( (item) => {

              if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)\b/i.test(item)){
                types['whisky'].count = parseInt(types['whisky'].count) + 1;
              } 
              
              // Brandy 🍷
              else if(/br\w*nd\w*y\b/i.test(item)){
                types['brandy'].count = parseInt(types['brandy'].count) + 1;
              }
              
              // Vodka 🥔
              else if(/(vodka)\b/i.test(item)){
                types['vodka'].count = parseInt(types['vodka'].count) + 1;
                // this.setNewIngredientType('Vodka 🥔')
              }
              
              // Rum 🍹
              else if(/\b(rh*ums?)\b/i.test(item)){
                // this.setNewIngredientType('Rum 🍹')
                types['rum'].count = parseInt(types['rum'].count) + 1;
              }
              
              // Gin 🍸
              else if(/\b(gin)\b/i.test(item)){
                // this.setNewIngredientType('Gin 🍸')
                types['gin'].count = parseInt(types['gin'].count) + 1;
              }
              
              // Tequila 🌵
              else if(/\b(te(c|q)\w*la)|sotol|rep(o|a)sado\b/i.test(item)){
                // this.setNewIngredientType('Tequila 🌵')
                types['tequila'].count = parseInt(types['tequila'].count) + 1;
              }
              
              // Liqueur 🥂'
              else if(/\b(liq|lic)\w*r\b/i.test(item)){
                // this.setNewIngredientType('Liqueur 🥂');
                types['liqueur'].count = parseInt(types['liqueur'].count) + 1;
              } 
              
            })
          } else {

            var itemType = ingredientType.toLowerCase();

            if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)\b/i.test(itemType)){
              types['whisky'].count = parseInt(types['whisky'].count) + 1;
            } 
            
            // Brandy 🍷
            else if(/br\w*nd\w*y\b/i.test(itemType)){
              types['brandy'].count = parseInt(types['brandy'].count) + 1;
            }
            
            // Vodka 🥔
            else if(/(vodka)\b/i.test(itemType)){
              types['vodka'].count = parseInt(types['vodka'].count) + 1;
              // this.setNewIngredientType('Vodka 🥔')
            }
            
            // Rum 🍹
            else if(/\b(rh*ums?)\b/i.test(itemType)){
              // this.setNewIngredientType('Rum 🍹')
              types['rum'].count = parseInt(types['rum'].count) + 1;
            }
            
            // Gin 🍸
            else if(/\b(gin)\b/i.test(itemType)){
              // this.setNewIngredientType('Gin 🍸')
              types['gin'].count = parseInt(types['gin'].count) + 1;
            }
            
            // Tequila 🌵
            else if(/\b(te(c|q)\w*la)|sotol|rep(o|a)sado\b/i.test(itemType)){
              // this.setNewIngredientType('Tequila 🌵')
              types['tequila'].count = parseInt(types['tequila'].count) + 1;
            }
            
            // Liqueur 🥂'
            else if(/\b(liq|lic)\w*r\b/i.test(itemType)){
              // this.setNewIngredientType('Liqueur 🥂');
              types['liqueur'].count = parseInt(types['liqueur'].count) + 1;
            }
          }
          
          return types;
        })
      })
      
      return types;
    },
    inventoryCount: (state) => {
      return state.inventory.length;
    },
    recipeCount: (state) => {
      return state.recipes.length;
    },
    // These are the computed items 
    getRecipeById: (state) => (id) => {
      var foundRecipe = state.recipes.find(item => item.id === id);
      return {id: foundRecipe.id, ...foundRecipe}
    }, 
    getInventoryById: (state) => (id) => {
      return state.inventory.find(item => item.id === id);
    }, 
    getCollectionById: (state) => (id) => {
      return state.collections.find(item => item.id === id);
    },
    user: (state) => {
      return state.user
    },
    loggedIn:(state) =>{
      return state.user.loggedIn;
    },
    // Returns inventory where maker, type, or variant is referenced by ingredient. 
    // @Returns [ Ingredients ] 
    getInventoryByIngredient: (state) => (ingredient) => {
      var searchString = ''
      
      if(typeof ingredient == 'object'){
        searchString = ingredient.name.split(' ').join('|')
      } else {
        searchString = ingredient.split(' ').join('|')
      }
      
      return state.inventory.some( (item) => {
        var regStringIng = new RegExp(`(${searchString})`, 'i')
        if( regStringIng.test(item.maker) || regStringIng.test(item.type) || regStringIng.test(item.variant) ){
          return true;
        } 
        
      })
    }, 
    getInventoryRunningLow: (state) => {
      return state.inventory.filter( (item) => {
        var origQty = item.originalQty || 750;
        var percentLeft = Math.round((item.qty / origQty)*100);//item.qty / origQty; 
        if(percentLeft < 25){
          return true;
        } else { 
          return false;
        }
      }).map( (item) => {

        var bottleSize = item.originalQty || 750;
        
        // normalize whiskey
        var tempType = ''
        if(item.type.toLowerCase() == 'whisky' || item.type.toLowerCase() == 'whiskey'){
          tempType = 'whisky'
        } else {
          tempType = item.type.toLowerCase()
        }

        // console.log(tempType)

        return {
          searchLink: `https://www.totalwine.com/search/all?text=${encodeURIComponent(item.maker)}`, 
          percentLeft: Math.round((item.qty / bottleSize)*100),
          primaryColor: state.inventoryTypes[tempType].primaryColor, 
          accentColor: state.inventoryTypes[tempType].secondaryColor, 
          emoji: state.inventoryTypes[tempType].emoji,
          id: item.id,
          ...item
        }
      })
    }
  },
  actions: {
    bindRecipes: firestoreAction( ({state, bindFirestoreRef}) => {
      if(!state.user.loggedIn) return;
      
      let userId = state.user.data.id;
      bindFirestoreRef('recipes', DB.collection('recipes').where('ownerId', '==', userId))
    }),
    bindIngredients: firestoreAction( ({state, bindFirestoreRef}) => {
      if(!state.user.loggedIn) return;
      
      let userId = state.user.data.id;
      bindFirestoreRef('inventory', DB.collection('ingredients').where('ownerId', '==', userId))
    }),
    bindCollections: firestoreAction( ({state, bindFirestoreRef}) => {
      if(!state.user.loggedIn) return;

      let userId = state.user.data.id;
      bindFirestoreRef('collections', DB.collection('collections').where('ownerId', '==', userId));
    }),
    getPopularRecipes({commit}){
      cocktaildb.getPopularDrinks( recipes => {
        commit('SET_POPULAR_DRINKS', recipes)
      })
    },
    fetchUser({ commit }, user){
      commit("SET_LOGGED_IN", user !== null);

      if(user){
        commit("SET_USER", {
          displayName: user.displayName, 
          email: user.email, 
          id: user.uid
        })
      } else {
        commit("SET_USER", null)
      }
    }, 
    logout({commit}){
      commit('LOGOUT');
    },
    addRecipe: firestoreAction((context, payload) => {
      // console.log(payload)
      let userId = context.state.user.data.id;
      let newRecipe = {ownerId: userId, ...payload};
      
      return DB.collection('recipes').add(newRecipe)
    }), 
    addIngredient: firestoreAction( (context, payload) => {
      let userId = context.state.user.data.id;
      let newIngredient = {ownerId: userId, ...payload};
      
      return DB.collection('ingredients').add(newIngredient);
    }), 
    addCollection: firestoreAction( (context, payload) => {
      let userId = context.state.user.data.id;
      let newCollection = {
        ownerId: userId, 
        ...payload
      };

      return DB.collection('collections').add(newCollection)
    }),
    updateIngredient: firestoreAction((context, payload) => {
      let documentId = payload.id; 
      let changes = payload.changes;
      
      return DB.collection('ingredients')
        .doc(documentId)
        .update(changes)
        .then( () => {
          console.log('Updated successfully!')
        })
        .catch( (error) => {
          console.log(error)
        })
    }), 
    deleteIngredient: firestoreAction((context, payload) => {
      let documentId = payload.id;
      
      return DB.collection('ingredients')
        .doc(documentId)
        .delete()
    }), 
    deleteRecipe: firestoreAction((context, payload) => {
      let documentId = payload.id; 

      return DB.collection('recipes')
        .doc(documentId)
        .delete()
    })
    
  },
  modules: {
  }
})
