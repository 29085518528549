<template>
	<v-combobox
	  :readonly="readonly"
	  label="Subtype"
	  hint="What's the subtype? Examples: Liqueur = Coffee, Tequila = Reposado" persistent-hint
	  :value="subtype"
	  @change="changed"
	  :items="subtypes"
	></v-combobox>
</template>
<script>
	export default {
		name: 'IngredientPicker', 
		data: () => ({
		}), 
		props: {
			type: {
				type: String,
				required: true,
			},
			subtype: String,
			readonly: {
				type: Boolean, 
				default: function() {
					return false;
				}
			}
		},
		methods: {
			changed(value){
				this.$emit('update:subtype', value)
			}
		}, 
		computed: {
			subtypes(){
				switch (this.type) {
				  case 'Rum':
					return ["Dark", "Flavored", "Gold", "Light", "Spiced"];
					break;
				  case 'Tequila': 
					return ['Mezcal', 'Silver', 'Reposado', 'Anejo', 'Extra Anejo', 'Sotol'];
					break;
				  case 'Gin': 
					return ["Juniper-Flavored", "Gin", "Distilled", "London"];
					break;
				  case 'Whiskey': 
					return ["Canadian", "Scotch", "Irish", "American"]; 
					break;
				  default:
					return [];
					break;
				}
			}, 
		}
	}
</script>