import Vue from 'vue'
import VueRouter from 'vue-router'

const Home          = () => import('../views/Home.vue');

const Inventory     = () => import('../views/Inventory.vue');
const AddInventory  = () => import('../views/AddInventory.vue');
const EditInventory = () => import('../views/EditInventory.vue');

const Recipes       = () => import('../views/Recipes.vue');
const Recipe        = () => import('../views/EditRecipe.vue');
const AddRecipe     = () => import('../views/AddRecipe.vue');

const Login         = () => import('../views/Login.vue');
const Signup        = () => import('../views/Signup.vue');

const Find          = () => import('../views/FindADrink.vue');

const Collections = () => import('../views/Collections.vue');
const AddCollection = () => import('../views/AddCollection.vue');
const EditCollection = () => import('../views/EditCollection.vue');

Vue.use(VueRouter)

import { App } from '../firebase/app'
import { Auth } from '../firebase/auth'

App.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = Auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  }
)};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/collections',
    name: 'Collections', 
    component: Collections
  },
  {
    path: '/collection/new',
    name: 'AddCollection',
    component: AddCollection, 
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/collection/:id',
    name: 'Collection', 
    component: EditCollection
  },
  {
    path: '/collection/:id/edit',
    name: 'EditCollection', 
    component: EditCollection,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/inventory/add',
    name: 'AddInventory',
    component: AddInventory,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/inventory/:id',
    name: 'InventoryDetail',
    component: EditInventory,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/inventory/:id/edit',
    name: 'EditInventory',
    component: EditInventory,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/recipes/add',
    name: 'AddRecipes',
    component: AddRecipe,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/recipe/:id',
    name: 'Recipe',
    component: Recipe,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/recipe/:id/edit',
    name: 'EditRecipe',
    component: Recipe,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/recipes/find',
    name: 'Find',
    component: Find,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if(requiresAuth && !await App.getCurrentUser()){
    next('login')
  } else {
    next();
  }
})

export default router
