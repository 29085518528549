<template>
	<v-text-field
		:readonly="readonly"
		label="Qty & Measurement"
		hint="How much & what kind?"
		placeholder="2"
		:value="qty"
		@change="changedQty"
		type="number"
	>
	
		<!-- Custom Quantity Type -->                    
		<template v-slot:append-outer>
			<v-menu :disabled="readonly || disableType" rounded="b-xl" offset-y :close-on-content-click="false" v-model="ingredientMeasurementType">
				<template v-slot:activator="{attrs, on}">
					<v-btn color="info" small text 
						v-bind="attrs" 
						v-on="on"
					>
						{{measurement == 5 ? customMeasurement : measurement}}
						<v-icon>{{ ingredientMeasurementType ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
					</v-btn>
				</template>
			
				<v-list>
					<v-list-item-group :value="measurement" @change="changedMeasurement" mandatory>
						<v-list-item v-for="msrmnt in measurements" :key="msrmnt" :value="msrmnt">
							<v-list-item-title v-text="msrmnt"></v-list-item-title>
						</v-list-item>
						
						<v-list-item @click="addNewMeasurement = true" :value="customMeasurement">
							<v-list-item-title v-if="!addNewMeasurement">New Measurement</v-list-item-title>
							<template v-else>
								<form v-on:submit.prevent="addNewMeasurement = false">
									<v-text-field autofocus flat v-model="customMeasurement"></v-text-field>
								</form>
							</template>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-menu>
		</template>
		
  </v-text-field>
</template>
<script>
	export default {
		name: 'QtyMeasurementPicker',
		props: {
			qty: [Number, String], 
			measurement: String ,
			readonly: {
				type: Boolean, 
				default: function() {
					return false;
				}
			}, 
			disableType: {
				type: Boolean, 
				default: function() {
					return false;
				}
			} 
		},
		data: () => ({
			addNewMeasurement: false,
			ingredientMeasurementType: false,
			customMeasurement: '',
			measurements: [
				"mL", "oz", "each", "twist", "dash", "cup", " "
			],
		}), 
		methods: {
			changedQty(value){
				this.$emit('update:qty', value)
			}, 
			changedMeasurement(value){
				this.$emit('update:measurement', value)
			}
		}
	}
</script>