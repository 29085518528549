<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <!-- <template v-if="user.loggedIn" v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar color="red">
            <span class="white--text headline">
              {{user.data.displayName.split(" ")[0].substring(0, 1)}}
            </span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{user.data.displayName}}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{user.loggedIn ? 'Signed In' : ''}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template> -->

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Drincolo
            </v-list-item-title>
            <v-list-item-subtitle>
              Making drinks the easy way!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item exact v-for="item in links" :key="item.link" :to="item.link" link>
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="pa-2">
              <v-btn @click="user.loggedIn ? signOut() : ''" :to="!user.loggedIn ? '/login' : ''" block :color="user.loggedIn ? 'error' : 'indigo'">
                {{user.loggedIn ? 'Logout' : 'Login'}}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dense flat
      color="green"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Drincolo</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn rounded outlined :to="!user.loggedIn ? '/login' : ''">
        {{user.loggedIn ? user.data.displayName : 'Signin'}}
        <v-icon right>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view @showAlert="showalert"></router-view>
      </v-container>
    </v-main>

    <v-snackbar dark
      v-model="alert.show"
      :color="alert.color"
      :top="alert.top"
    >
      {{ alert.text }}
    </v-snackbar>

    <v-snackbar top centered :value="updateExists" color="info">
      An update is available!
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
    </v-snackbar>

  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Auth } from './firebase/auth.js'

  import update from './mixins/update'


  export default {
    name: 'App',
    components: {},

    mixins: [update],

    data: () => ({
      //
      drawer: false,
      alert: {
        show: false,
        top: false,
        text: '',
        color: '',
      },
      links: [
        {
          title: 'Home',
          link: '/',
          icon: 'mdi-home-circle-outline',
        },
        {
          title: 'Saved Recipes',
          link: '/recipes/',
          icon: 'mdi-bookshelf'
        },
        {
          title: 'Find a Recipe',
          link: '/recipes/find',
          icon: 'mdi-crystal-ball'
        },
        {
          title: 'Liquor Cabinet',
          link: '/inventory',
          icon: 'mdi-bottle-tonic-skull-outline'
        },
        {
          title: 'Collections',
          link: '/collections',
          icon: 'mdi-test-tube'
        }
        // {
        //   title: 'Orders',
        //   link: '/orders',
        //   icon: 'mdi-glass-cocktail'
        // }
      ]
    }),
    computed: {
      ...mapGetters({
        user: 'user'
      })
    },
    mounted(){
      // Set dark mode to whatever the client dictates
      if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
        this.$vuetify.theme.dark = true;
      }

      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        let newColorScheme = e.matches ? 'dark' : 'light';
        this.$vuetify.theme.dark = newColorScheme == 'dark' ? true : false;
      })

      Auth.onAuthStateChanged(user => {
        this.$store.dispatch('bindRecipes')
        this.$store.dispatch('bindIngredients')
        this.$store.dispatch('bindCollections')
        this.$store.dispatch('getPopularRecipes')
      })
    },
    methods: {
      showalert(a){
        this.alert.top = a.top ?? false;
        this.alert.text = a.text;
        this.alert.color = a.type;

        this.alert.show = true;
      },
      signOut(){
        Auth.signOut()
          .then(() => {
            this.$router.replace({
              name: 'Home'
            })
          })

        this.$store.dispatch('logout');
      }
    }
  };
</script>
