<template>
	<v-select
	  :readonly="readonly"
	  required
	  label="Type*"
	  hint="Required. What type is this? Tequila? Whiskey? Liqueur?" persistent-hint
	  :value="type"
	  @change="changed"
	  :items="types"
	></v-select>
</template>
<script>
	export default {
		name: 'IngredientPicker', 
		data: () => ({
			types: ['Whiskey', 'Brandy', 'Vodka', 'Rum', 'Gin', 'Tequila', 'Liqueur', 'Other'], 
		}), 
		props: {
			type: String,
			readonly: {
				type: Boolean, 
				default: function() {
					return false;
				}
			}
		},
		methods: {
			changed(value){
				this.$emit('update:type', value)
			}
		}
	}
</script>