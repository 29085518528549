<template>
	<v-combobox
		:readonly="readonly"
		label="Variant"
		hint="What variant is this? Reposado? Coffee" persistent-hint
		:items="variants"
		@change="changed"
		:value="variant"
	></v-combobox>
</template>
<script>
	export default {
		name: 'IngredientPicker', 
		data: () => ({
		}), 
		props: {
			subtype: {
				type: String
			}, 
			variant: String, 
			readonly: {
				type: Boolean, 
				default: function() {
					return false;
				}
			}
		},
		methods: {
			changed(value){
				this.$emit('update:variant', value)
			}
		}, 
		computed: {
			variants(){
				switch(this.subtype) {
					case 'Canadian': 
						return ['Blended', 'Single Malt', 'Rye']
						break;
					case 'Scotch': 
						return ['Single Malt', 'Grain', 'Blended']
						break;
					case 'Irish': 
						return ['Single Grain', 'Blended', 'Single Pot', 'Single Malt'];
						break;
					case 'American':
						return ['Corn', 'Rye', 'Bourbon', 'Blended', 'Wheat', 'Single Malt'];
						break;
					default: 
						return [];
						break;
				}
			},  
		}
	}
</script>